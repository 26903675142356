<template>
	<Transition name="slide-fade" appear>
        <div class="xl:w-4/6 2xl:w-2/6 m-auto shadow-t3 rounded flex justify-center bg-white  container"> 
            <div class="p-12 m-auto rounded items-center relative md:mt-0">
                <router-link :to="{name:'Home_Customer'}" class=" text-md py-2  font-normal block w-full whitespace-nowrap bg-transparent text-blue-400">Back to home</router-link>

                <div class="">
                            <h1 class="text-xl font-bold text-t3 sm:text-2xl dark:text-white" >Send a Booking Request</h1>
                            <h2 class="text-sm mt-8 font-medium text-gray-400 sm:text-lg dark:text-white" >This form will allow you to send us a booking request, we will keep you updated about your request's status.</h2>
                
                </div>

                <div class="w-5/6 m-auto mt-12 text-center">
                                    <div class=" relative">
                                        <label for="datepick" class="text-sm text-gray-400 mt-8">Highlighted dates have available slots </label>
                                        <Datepicker id="datepick" :min-date="new Date()" @update:modelValue="newSlots" v-model="assessDate" :highlight="highlighted" :enableTimePicker="false" datePicker placeholder="Select Date"  class="mb-8 form-control block w-full text-xl font-normal text-gray-700 bg-white rounded transition ease-in-out m-0 focus:outline-none"/>
                                        <select  v-model="req.slot" class="h-12  form-control block w-full p-3 text-xl font-normal text-gray-400 bg-white focus:text-t3 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                                            <option v-if="freeSlots.length === 0" disabled :value="null">No Slots Available for the Selected Date</option>
                                            <option v-if="freeSlots.length !== 0" disabled :value="null">Select Slot</option>
                                            <option v-for="slot in freeSlots"  :value="slot.id" :key="slot.id">Date:{{new Date(slot.date).toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})}} Time:{{slot.start_time}}</option> 
                                        </select>
                                    </div>
                                    <div class=" flex flex-shrink-0 flex-wrap items-center justify-center p-4 rounded-b-md">
                                        <button type="button"
                                        @click="BookReqSend" class=" w-48 m-auto  font-medium bg-t3 p-3 rounded mt-14 text-white hover:text-white hover:bg-t3-secondary block">
                                        Send Request
                                        </button>
                                    </div>
                </div>



            </div>
        </div>
	</Transition>
</template>

<script>
      import ReportServices from '@/services/ReportServices';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import addDays from 'date-fns/addDays';
import Swal from 'sweetalert2';
import { ref, watchEffect } from 'vue';
import {
mapActions,
mapGetters, mapState
} from 'vuex';
import BookingServices from "../services/BookingServices";

    export default {
        components: {
                Datepicker 
            },
        setup() {
        const date = ref(new Date());
         const highlightedDates = ref([
          addDays(new Date(), 1),
          addDays(new Date(), 2),
          addDays(new Date(), 3),
        ])
        
        return {
            date,
            highlightedDates
            
        }
        },

        data() {
            return {
                highlighted:[],
                freeSlots:[],
                assessDate:null,
                req:{
                    slot:null
                }
            }
        },
        
        methods: {
            ...mapActions({
                getbooked: 'Requests/getBookings',
                getslots: 'Slots/getSlotsForDate',
                getallslots: 'Slots/getAllSlots',
            }),
            newSlots(data){
            if(data){
                
                this.assessDate=data
                let dat= this.assessDate.toISOString()
                this.getslots(dat.substr(0,10)).then(()=>{
                    if(this.slots){
                    this.freeSlot=this.slots.filter(slot => slot.assessment === null)
                    }}
                )
            }  
        },
            BookReqSend(){
                if(this.req.slot)
                {
                    let userid= JSON.parse(localStorage.getItem('userdetail')).id
                    let sendingObj = {
                        customer:userid,
                        slot:this.req.slot,
                    }
                    const getFormData = object => Object.entries(object).reduce((fd, [ key, val ]) => {
                    if (Array.isArray(val)) {
                    val.forEach(v => fd.append(key, v))
                    } else {
                    fd.append(key, val)
                    }
                    return fd
                    }, new FormData())
                    BookingServices.newBooking(getFormData(sendingObj)).then(()=>{
                                Swal.fire({
                                icon:"success",
                                title:"Success!",
                                text:"Booking request sent",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 3000,})
                        this.getbooked()
                        this.updateSlots()
                        this.freeSlots = this.slots.filter(slot => slot.assessment === null)
                        this.$forceUpdate()
                    }).catch((error)=>{
                        let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                    })
                    
                }
                else{
                    Swal.fire({
                                icon:"warning",
                                title:"Hold",
                                text:"Fill in all the required fields first",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 3000,})
                }
            },
            bookingCustomer(sentID){
			return this.customers.find(cus => cus.id === sentID)},
            bookingSlot(sentID){
			return this.slots.find(cus => cus.id === sentID)},	
            newRequestModal(){
                document.getElementById("vv").click()
            },
			scopeCheck(scope){
				let user= JSON.parse(localStorage.getItem('userdetail'))
				

				if(user.is_company){
                    
				return true;
				}else{
				return localStorage.getItem('scopes').includes(scope)}
			}		
            
        },
        computed: {
            openBookings(){
                return this.booked.filter(b => b.status === "sent" )
            },

            ...mapState({
                allSlots: state => state.Slots.slots,
                slots: state => state.Slots.slotD,
                booked: state => state.Requests.bookings,
                customers: state => state.Users.customers
            }),
            ...mapGetters('Users', [
            'customerOnly'
            ])
        },

        created() {
            watchEffect(() => {
             if(this.assessDate){
                let dat= this.assessDate.toISOString()
                this.getslots(dat.substr(0,10)).then(()=>{
                this.freeSlots = this.slots.filter(slot => slot.assessment === null)

            })
                }
                            if(this.allSlots){
                this.highlighted=[]
                    this.allSlots.filter(slot => slot.assessment === null).forEach((s)=>{
                        this.highlighted.push(new Date(s.date))
                    })
                    }
            
        
            
            })
            this.getallslots()
            this.getbooked()


}
    }
</script>

<style>

</style>